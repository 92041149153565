<template>
    <div>
        <Toolbar
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Item.xls"
        />
        <div class="p-1">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header" cellpadding="0" cellspacing="5" border="0" class="table table-hover table-strip">
                            <thead>
                                <tr class="">
                                    <th width="50px" class="text-center">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="h9 text-center">{{fl.name}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-210) +'px;'">
                        <table class="table table-hover table-strip table-bordered col-9">
                            <tbody>
                                <tr v-for="(dt, index) in recs" :key="dt.ua_id" class="w3-hover-pale-blue w3-small cursor" :class="index == selectedRow ?'w3-pale-blue':''">
                                    <td width="50px" class="text-center">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :width="findObjFind('width','ua_name')" class="w3-hide-small w3-hide-medium w3-border-right">
                                        {{dt.ua_name}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ua_dept')" class="w3-hide-small w3-hide-medium w3-border-right text-center">
                                        {{deptDtl(dt.ua_dept)}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ua_level')" class="w3-hide-small w3-hide-medium w3-border-right text-center">
                                        {{dt.ua_level}}
                                    </td>
                                    <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ua_lokasi')" class="w3-hide-small w3-hide-medium w3-border-right">
                                        {{loktDtl(dt.ua_lokasi)}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <div >
            <nav class="Page navigation h9 bg-light bg-gradient shadow">
                <div class="d-flex justify-content-center">
                    <ul class="pagination text-center">
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                        <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                        <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                        </ul>
                </div>
            </nav>
        </div>

        <div id="modal-form" class="modal row top-50 start-50 translate-middle ms-md-0 ms-1 modal-md">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
            <div class="modal-header pb-0">
                <button onclick="document.getElementById('modal-form').style.display='none'"
                class="btn w3-display-topright btn-lg">&times;</button>
                <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Add / Edit data</p>
            </div>
            <div class="modal-body">
                <form class="px-3" id="frmGdg" v-on:submit.prevent="saveData" autocomplete="off">
                    <div class="mb-3">
                            <label for="ua_name" class="form-label h9 fw-bold">Username</label>
                        <input type="text" id="ua_name" v-model="rec.ua_name" class="form-control mb-3" required>

                        <label for="ua_pass" class="form-label h9 fw-bold">Password</label>
                        <input type="password" id="ua_pass" v-model="rec.ua_pass" class="form-control mb-3" required>

                        <label for="ua_dept" class="form-label h9 fw-bold">Department</label>
                        <select v-model="rec.ua_dept" class="form-select mb-3" style="width: 100%;" required>
                            <option class="" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                                {{ option.text }}
                            </option>
                        </select>

                        <div class="row">
                            <div class="col-md-2">
                                <label for="ua_level" class="form-label h9 fw-bold">Level</label>
                                <input type="number" id="ua_level" v-model="rec.ua_level" class="form-control mb-3" required>
                            </div>
                            <div class="col-md-10">
                                <label for="ua_lokasi" class="form-label h9 fw-bold">Location</label>
                                <model-list-select
                                    :list="lok"
                                    class="w3-small form-select"
                                    option-value="li_id"
                                    option-text="li_nama"
                                    v-model="rec.ua_lokasi"
                                    placeholder="Pilih Sparepart"
                                ></model-list-select>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div class="modal-footer"  style="margin-top: 15px;">
                <h6 class="w3-right">
                <button form="frmGdg" class="btn btn-success rounded" type="submit"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="btn btn-danger ms-2 rounded" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                </h6>
            </div>
            </div>
        </div>
        </div>


    </div>
</template>
<script>
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar.vue";

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import { ModelListSelect } from "vue-search-select";
// import $ from "jquery";

export default {
    name : 'Inventaris',
    components: {
        Toolbar,
        ModelListSelect
    },
        data() {
        return {
            rec: {},
            recs: [],
            lok:[],
            dept: [
                {value:'1',text:'IT'},
                {value:'2',text:'Purchase'},
                {value:'3',text:'Kendaraan'},
                {value:'4',text:'Gudang'},
            ],
            screenHeight: 0,
            selectedRow: -1,
            departement: '6',
            pages: { halaman: 1, batas: 0, ttlhalaman: 0, ttlrec: 0, limit: Config.perpage, search: '' },
            filters: {
                operator: 'AND',
                fields: [
                    { fieldFind: '', key: 'ua_name', type: 'text', name: 'Nama User', filter: true, width: '200px', grid: true },
                    { fieldFind: '', key: 'ua_dept', type: 'text', name: 'Departemen', filter: true, width: '100px', grid: true },
                    { fieldFind: '', key: 'ua_level', type: 'text', name: 'User level', filter: true, width: '50px', grid: true },
                    { fieldFind: '', key: 'ua_lokasi', type: 'text', name: 'Lokasi', filter: true, width: '200px', grid: true },
                ],
                find: [],
            },
            json_fields : {
                'SO' : 'ta_so',
                'NAMA CUSTOMER' : 'ta_IdCust',
                'TANGGAL PENGAJUAN' : 'ta_date',
                'OUT STANDING SO' : 'ta_outstandingSO',
                'NOMINAL' : 'ta_amt',
                'KREDIT LIMIT' : 'ta_creditLimit',
                'TEMPO' : 'ta_top',
                'PEMBUAT SO' : 'changed_by',
                'STATUS' : 'ta_approved',
                'ALASAN PENGAJUAN' : 'ta_reason'
            },
        };
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        deptDtl(deptId) {
            const department = this.dept.find(d => d.value === deptId);
            return department ? department.text : 'Department not found';
        },
        loktDtl(lokId) {
            const lok = this.lok.find(d => d.li_id === lokId);
            return lok ? lok.li_nama : 'Department not found';
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search
            }
            axios.post("admin/ApiAdminG.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        async showAdd() {
            let self = this;
            self.stsAdd = true;


            document.getElementById("modal-form").style.display = "block";

        },
        showDelete(){

        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih gudang.!');
                return false;
            }else{
                this.stsAdd = false;
                document.getElementById('modal-form').style.display='block';

            }
        },
      saveData(event){
            let self = this;
            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            }else{
                self.rec.pfunction = "add";
            }
            // self.recCust['userentry'] = localStorage.username;
            axios.post("admin/ApiAdminG.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    document.getElementById('modal-form').style.display='none';
                    this.loadData();
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        setInit() {
            let self = this;

            axios.post("invent/ApiLokasi.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.lok = response.data.rec;
            });
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("id-ID",{ style: 'currency', currency: 'IDR' }).format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
        this.loadData();

    }
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>